<template>
    <NoButtonModal :open='open' @close='cancel' id='signature_as_popup'>
        <button class='close-corner-x' type='button' @click.prevent='cancel'>
            <span class='material-icons-outlined dark-text'>close</span>
        </button>
        <div class="align-left">
            <h2>Create and Store Your Signature</h2>
            <p>Use your mouse or touchpad to sign your signature in the box below.</p>
        </div>
        <VueSignaturePad class='signature-pad' ref='addSignature' />
        <div class='button-container align-right signature-btns'>
            <button class='secondary bottom-0 signature-button' @click='handleSignatureModalClose'>Cancel</button>
            <button class='secondary bottom-0 signature-button' @click='clearSignature'>Clear</button>
            <button class='primary bottom-0 signature-button' @click='saveSignature'>Save</button>
        </div>
    </NoButtonModal>
</template>

<script>
    import { signatures } from '@/util/apiRequests';
    import NoButtonModal from '@/components/general/modals/NoButtonModal';
    import { mapState } from 'vuex';

    export default {
        name: 'CreateTemporarySignaturePopup',
        components: { NoButtonModal },
        props: {
            open: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                signatureInfo: {},
                hoveringSignatureBox: false,
                signatureModalOpen: false,
                loading: true
            };
        },
        computed: {
            ...mapState({ user: 'user' }),
            userId() {
                return this.user.id;
            },
            signaturePad() {
                return this.$refs.addSignature;
            },
            signatureDataUrl() {
                if (this.signatureInfo.file?.Body) {
                    return `data:image/png;base64, ${this.signatureInfo.file.Body}`;
                }

                return null;
            }

        },
        methods: {
            async getSignature() {
                // const res = await this.$api.get(signatures.getSignature(this.user.id), { params: { t: 'user' } });
                //
                // if (res.status === 404) {
                //     return;
                // }
                //
                // if (res.status < 200 || res.status >= 300) {
                //     //this.$toasted.error('Failed to retrieve signature data');
                //     return;
                // }
                //
                // this.signatureInfo = res.data || {};
                // await this.$nextTick();
                //
                // this.signaturePad.resizeCanvas();
                //
                // this.signaturePad.fromDataURL(
                //     this.signatureInfo?.file?.Body
                //         ? `data:image/png;base64, ${this.signatureInfo.file.Body}`
                //         : undefined
                // );
                //this.signaturePad.resizeCan
            },
            handleSignatureModalClose() {
                this.signaturePad.clearSignature();
                this.$emit('close');
            },
            clearSignature() {
                this.signaturePad.clearSignature();
            },
            cancel() {
                this.handleSignatureModalClose();
            },
            async saveSignature() {
                const { data, isEmpty } = this.signaturePad.saveSignature();
                if (!isEmpty) {
                    // const file = new FormData();
                    // file.append('file', this.dataURIToBlob(data));
                    // file.append('signature', data);
                    // file.append('resourceId', this.user.id);
                    // file.append('type', 'user');
                    // file.append('fileId', this.signatureInfo.file_id || null);
                    //
                    // const res = await this.$api.post(signatures.saveSignature(), file);
                    //
                    // if (res.status < 200 || res.status >= 300) {
                    //     //this.$toasted.error('Failed to save signature. Please try again later.');
                    //     return;
                    // }
                    //
                    // this.signatureInfo.file_id = res.data.fileId;
                    // this.signatureInfo.id = res.data.signId;
                    //
                    // this.$toasted.success('New signature saved');
                    // this.$emit('close', res.data);
                    // await this.handleSubmit();
                    this.$emit('close', data);


                }
            },
            dataURIToBlob(dataURI) {
                const splitDataURI = dataURI.split(',');
                const byteString =
                    splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
                const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

                const ia = new Uint8Array(byteString.length);
                for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

                return new Blob([ia], { type: mimeString });
            }

        },
        async created() {
            await this.getSignature();
        }
    };
</script>
