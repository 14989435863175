<template>
    <div>
        <div v-if="!loading" class="rec-data-div" :class="pageMeta.component_name.toLowerCase()+'-page'">
            <form @submit.prevent="updateRecord">
                <div class="flex space-between do-not-print-me form-header">
                    <div class="name-id">
                        <FormClient :client_id="client_id" :form_data_id="record_id" @getClient="getClient"/><br>
                    </div>
                    <div class="align-right">
                        <FormCompany @getCompany="getCompany"/>
                        <div class="align-right" v-if="company">
                            <DynamicImage alt="logo" :urlToRetrieveFile="company.logo ? file.getFile(company.logo) : null" class="company-logo" />
                        </div>
                    </div>
                </div>
                <div class="do-not-print-me">
                    <h2 class="margin-0">{{ pageMeta.title }}</h2>
                </div>

                <button type="button" @click="edit" v-if="signature_ids.length && original_signature_ids.length">
                    Edit & Create Addendum
                </button>
                <button v-if="original_signature_ids.length && !signature_ids.length" class="align-right" type="button" @click="signature_ids=original_signature_ids">
                    Cancel Edit
                </button>
                <div class="dark-text header-w-bg addendum" v-if="original_signature_ids.length && !signature_ids.length">
                    You are now editing the original signed document to create an addendum
                </div>

                <hr class="m-15 do-not-print-me" />

                <!-- put generated code BELOW here -->
                <div class="cert-completion">

                    <div >
                        <div class="bottom-20">
                            <DynamicImage alt="logo" :urlToRetrieveFile="company.logo ? file.getFile(company.logo) : null" class="company-logo" />
                        </div>
                        <h1>Certificate of Completion</h1>
                        <p>That certifies that</p>
                        <h2>
                            {{client.first_name }} {{ client.last_name }}
                        </h2>
                        <p>has completed 
<!--                            <span contenteditable="true" class="service-input" @input='onServiceInput'>{{rec.completedService ? rec.completedService : '(Enter service)'}}</span>-->
                            <editable-span v-model='rec.completedService' placeholder='(Enter service)'/>
                            <!--                                <input v-model='rec.completedService' placeholder='(Enter a service)' required/>-->
<!--                             <input class="service-input" type='text' v-model='rec.completedService' placeholder="Enter service" />  -->
                            at <span class="nowrap">{{ company.company_name }}</span> on <span class="nowrap">{{ date }}</span>.</p>

                    </div>

                    <!-- primary clinician -->
                    <div class="clinicians-sig align-right">
                        <div class="inline-block align-center">
                            <FormSignature :show_timestamp="false" :user_id="primaryClinicianId" :signature_ids="signature_ids" ref="form_signatures" @getSignatures="getSignatures" v-if="signature_ids.length > 0"/>
<!--                            <h3>{{primaryClinician}}</h3>-->
<!--                            <Dropdown v-model="rec.counselor" :options="counselorOptions" label="Primary" placeholder="Select a primary counselor" :disableEmpty="true"/>-->
                            <FormSignatureButton
                                class="cert-sign"
                                :rec="rec"
                                :original_rec="original_rec"
                                :signatures_info="signatures_info"
                                :fileName="pageMeta.component_name"
                                :signature_ids="signature_ids"
                                :record_id="record_id"
                                @resetView="reloadData"
                                @fail="fail2sign"
                                @showModal="showPdfModal"
                                :save="updateRecord"
                            />
                          <div><editable-span v-model='rec.clinicalDirector' placeholder='(Enter your role)'/></div>
                        </div>
                    </div>

                    <!-- clinician Director -->
    <!--                <div>-->
    <!--                    <div>-->
    <!--                        <FormSignature :user_id="primaryClinicianId" :signature_ids="signature_ids" ref="form_signatures" @getSignatures="getSignatures" v-if="signature_ids.length > 0"/>-->
    <!--                        <h3> {{primaryClinician}}</h3>-->
    <!--                        Clinician Director-->
    <!--                    </div>-->
    <!--                    <FormSignatureButton-->
    <!--                        :rec="rec"-->
    <!--                        :original_rec="original_rec"-->
    <!--                        :signatures_info="signatures_info"-->
    <!--                        :fileName="pageMeta.component_name"-->
    <!--                        :signature_ids="signature_ids"-->
    <!--                        :record_id="record_id"-->
    <!--                        @resetView="reloadData"-->
    <!--                        @fail="fail2sign"-->
    <!--                        @showModal="showPdfModal"-->
    <!--                        :save="updateRecord"-->
    <!--                    />-->
    <!--                </div>-->
                </div>

                <!-- put generated code ABOVE here -->

                <div class="align-right" v-if="!signature_ids.length">
                    <button v-if="original_signature_ids.length && !signature_ids.length" class="align-right inline-block secondary right-15" type="button" @click="signature_ids=original_signature_ids">
                        Cancel Edit
                    </button>
                    <ConfirmButton class="inline-block" @click="updateRecord()" :canedit="isEditable" />
                </div>
<!--                <FormSignature :user_id="user_id" :signature_ids="signature_ids" ref="form_signatures" @getSignatures="getSignatures" v-if="signature_ids.length > 0"/>-->
<!--                <FormSignatureButton-->
<!--                    :rec="rec"-->
<!--                    :original_rec="original_rec"-->
<!--                    :signatures_info="signatures_info"-->
<!--                    :fileName="pageMeta.component_name"-->
<!--                    :signature_ids="signature_ids"-->
<!--                    :record_id="record_id"-->
<!--                    @resetView="reloadData"-->
<!--                    @fail="fail2sign"-->
<!--                    @showModal="showPdfModal"-->
<!--                    :save="updateRecord"-->
<!--                />-->
            </form>
            <VIfWorkableModal
                :hasCloseButton="false"
                data-html2canvas-ignore="true"
                v-if="showCreatePdfModal"
            >
                <div>
                    <SignConvertPdfModal />
                </div>
            </VIfWorkableModal>
<!--            <create-signature-popup :open='showSignaturePopup' @close='closeSignaturePopup' v-if='showSignaturePopup'/>-->
            <create-temporary-signature-popup :open='showSignaturePopup' @close='closeSignaturePopup' v-if='showSignaturePopup'/>
        </div>
    </div>
</template>

<script>
    import SignConvertPdfModal from '@/components/formpieces/SignConvertPdfModal'
    import { dryalex } from '@/mixins/dryalex';
    import FormClient from '@/components/formpieces/FormClient'
    import FormCompany from '@/components/formpieces/FormCompany.vue';
    import FormSignature from '@/components/formpieces/FormSignatureNew';
    import FormSignatureButton from '@/components/formpieces/FormSignatureButtonNew'
    import VIfWorkableModal from '@/components/general/modals/vIfWorkableModal.vue';
    import { merge } from 'lodash'
    import { clients, companies, invoices, ledger } from '@/util/apiRequests';
    import DynamicImage from '@/components/general/image/Image';
    import { file } from '@/util/apiRequests';
    import { downloadPdf, openPdf } from '@/util/pdf';
    import dayjs from '@/util/dayjs';
    import EditableSpan from '@/components/editableSpan/editableSpan';
    import CreateTemporarySignaturePopup from '@/components/general/modals/CreateTemporarySignaturePopup';
    // import CreateSignaturePopup from '@/components/general/modals/CreateSignaturePopup';


    export default {
        name: 'CertificateCompletion',
        props:{
            record_id: {
                type: [String, Number],
                required: false,
                default: 0
            },
        },
        components: { CreateTemporarySignaturePopup, EditableSpan, FormClient, FormSignature, VIfWorkableModal, FormCompany, FormSignatureButton, DynamicImage, SignConvertPdfModal },
        data() {
            return {
                loading     : 1,
                updating    : 0,
                isEditable  : 1,
                loggedInUser: this.$store.state.user,
                user_id     : 0,
                client_id   : 0,
                page_id     : 0,
                rec         : {},
                original_rec : {},
                signature_ids : [],
                original_signature_ids : [],
                showCreatePdfModal : false,
                api_signs_needed: null,
                signatures_info: null,
                client: {},
                company: {},
                rows: [],
                level_id: 0,
                level: 'company',
                options: [],
                this_record_id : 0,
                router_push : 0,
                showSignaturePopup: false,
                counselors: {
                    primary_counselor: null,
                    secondary_counselor: null,
                    tertiary_counselor: null,
                },
                counselorOptions: [],
                file,
            }
        },
        computed: {


                primaryClinician(){
                    if(this.counselors.primary_counselor){
                        let index = this.counselorOptions.findIndex((c) => c.value == parseInt(this.counselors.primary_counselor));
                        return this.counselorOptions[index]?.text;
                    }
                    return '';
                },
                primaryClinicianId(){
                    if(this.counselors.primary_counselor){
                        let index = this.counselorOptions.findIndex((c) => c.text == this.counselors.primary_counselor);
                        return this.counselorOptions[index]?.value;
                    }
                    return '';
                },


                clinicalDirector(){
                    //todo: get this setting
                    return "CLINICAL DIRECTOR GOES HERE";
                },

                signatureDate(){
                    if(this.original_signature_ids.length){
                        return new Date(this.original_signature_ids[0].signedAt).toLocaleString('default', { month: 'long' }) + ' ' + new Date().getDate() + ', ' + new Date().getFullYear();
                    }
                    return '';
                },

                date(){
                    if(this.this_record_id == 0 || !this.original_signature_ids.length){
                        return new Date().toLocaleString('default', { month: 'long' }) + ' ' + new Date().getDate() + ', ' + new Date().getFullYear()
                    }
                    return this.signatureDate
                },

        },
        methods:{
            edit(){
                this.signature_ids=[];
                this.rec.clinicalDirectorSignature = null;
            },
            closeSignaturePopup(data) {
                this.showSignaturePopup = false;
                this.rec.clinicalDirectorSignature = data;
            },
            onServiceInput(e){
                this.rec.completedService = e.target.innerText;
            },
            async viewAsPdf() {
                const res = await this.$api.get(ledger.getStatementPdf(this.clientId, this.startDate, this.endDate));

                if (res.status < 300) {
                    openPdf(res.data, 'Statement');
                }
            },
            downloadPdf() {
                if (this.client) {
                    const fileName = `${this.client.first_name}_${this.client.last_name}_statement_${dayjs().format(
                        'YYYYMMDDHHmmss'
                    )}.pdf`;

                    downloadPdf(this.$refs.pdf, fileName);
                }
            },

            async getClientCounselors() {
                try {
                    let result = await this.$api.get(clients.getCounselors(this.client_id));
                    this.counselors.primary_counselor = result.data.counselors?.primary_counselor?.id.toString();
                    this.counselors.secondary_counselor = result.data.counselors?.secondary_counselor?.id.toString();
                    this.counselors.tertiary_counselor = result.data.counselors?.tertiary_counselor?.id.toString();

                } catch (err) {
                    this.$toasted.error('Could not retrieve client counselors.');
                }
            },


            async getCounselorOptions() {
                try {
                    let result = await this.$api.get(companies.getClinicians(this.$store.state.user.company_id));
                    this.counselorOptions = result.data.map((element) => {
                        return { text: `${element?.first_name} ${element?.last_name}`, value: element.id };
                    });
                    // this.counselorOptionsOriginal = this.counselorOptions;
                } catch (err) {
                    this.$toasted.error('Could not retrieve list of counselors.');
                }
            },
            async fail2sign() {
                this.showPdfModal(false)
            },
            async reloadData(signature){
                this.showCreatePdfModal = false;
                this.signature_ids.push(signature)
            },
            showPdfModal(bool){
                this.showCreatePdfModal = bool;
            },
            getClient(data){
                this.client = data;
            },
            getCompany(data){
                this.company = data;
            },
            //signatures that come from the FormSignatures component
            getSignatures(data){
                this.signatures_info = data ? data : [];
            },
            async updateRecord(e) {
                if (!this.updating) {
                    this.updating  = 1
                    try{e.preventDefault()}catch(error){/**/}

                    let xthis = await dryalex.form_data_update_record(this, e)
                    merge(this, xthis)
                    if (this.router_push) {
                        this.router_push = 1
                        this.$forceUpdate()
                    }
                    this.original_signature_ids = this.signature_ids
                }
            },
            async init() {
                let xthis = await dryalex.form_data_init(this)
                merge(this, xthis)
                this.loading = 0
            },
        },
        async created() {
            await this.init()
            await this.getClientCounselors()
            await this.getCounselorOptions()
            if(!this.rec?.counselor && (this.record_id == 0 || this.record_id == '0' || this.record_id == 'new')){
                this.rec.counselor = this.counselors.primary_counselor;
            }else{
                this.rec.counselor = parseInt(this.rec.counselor);
            }
        },
        watch: {
            showCreatePdfModal(newVal){
                if(window.innerWidth >= 768 && newVal == true) this.openModalOverlay();
                if(newVal == false){this.closeModalOverlay()}
            },
        }

    };
</script>