<template>
    <span @input="send" contenteditable='true' :placeholder='placeholder' id='editableSpan' ref=span>
       {{ inp }}
    </span>

</template>

<script>
    export default {
        name: 'editableSpan',
        props: ['value', 'placeholder'],
        data: function() {
            return {
                inp: this.value,
                ref_span: null
            }
        },
        methods: {
            send: function(event) {
                this.$emit('input', event.target.innerText)
            }
        },
        mounted(){
            if(!this.value){
                this.$set(this.$data, 'ref_span', this.$refs.span);
            }
        },
        watch: {
            ref_span: {
                handler: function(newVal, oldVal) {
                    if(newVal.innerHTML.trim() == ""){
                        newVal.innerHTML = "";
                    }
                }
            }
        }
    };
</script>

<style scoped>
    [contenteditable=true]:empty:before{
        content: attr(placeholder);
        pointer-events: none;
        display: inline-block; /* For Firefox */
        color: grey;
    }


</style>